import * as qs from "query-string";

export const HELP_CENTRE_URL_NZ = "https://help.figured.com/en/articles/7843103-consolidated-valuations-nz";
export const HELP_CENTRE_URL_AU = "https://help.figured.com/en/articles/7857229-consolidated-valuations-au";
export const HELP_CENTRE_URL_REST_OF_THE_WORLD = "https://help.figured.com/en/articles/7857238-consolidated-valuations";

/**
 * Returns the values of all query parameters in the given URL, or otherwise the URL of the window.
 *
 * @returns {Object}
 */
export function getQuery(url = window.location.href) {
    return qs.parse(url.split("?", 2)[1]);
}

/**
 * Returns the value of a query parameter in the given URL, or otherwise the URL of the window.
 *
 * @returns {String|undefined}
 */
export function getQueryParameter(name, url = window.location.href) {
    return getQuery(url)[name];
}

export function goToUrl(url) {
    window.location.href = url;
}
